<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else class="px-0 mx-0">
      <loading :loading="loading" />

      <!-- นำทาง -->
      <v-card elevation="0" class="v-card-margin-bottom-30">
        <v-card-title class="body-1">
          ออเดอร์
          <v-icon>mdi-chevron-right</v-icon>
          อัปเดท Tracking
        </v-card-title>
      </v-card>

      <v-card class="py-3 px-6 mx-0 v-card-margin-bottom-30">
        <v-row class="pb-2">
          <v-col cols="12" md="5">
            <v-row align="center">
              <v-col cols="12" lg="12" class="py-1" align="center">
                <h3><v-icon color="success" left>mdi-cloud-download</v-icon>ข้อมูล order</h3>

                <h3>Please export excel template</h3>
              </v-col>
              <v-col cols="12" md="12" class=" py-3" align="center">
                <v-btn :elevation="1"  color="success" class="mr-3 mt-1"><v-icon left>mdi-download</v-icon>
                  <vue-excel-xlsx
                      :data="dataTable"
                      :columns="excel_order_list_header"
                      :file-name="'template_update_tracking'"
                      :sheet-name="'template_update_tracking'"
                      >
                      Download template
                  </vue-excel-xlsx>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="5" align="center">
            <v-row class="my-3">
              <v-col cols="12"  lg="12" align="center">
                <h3> <v-icon left color="success" >mdi-cloud-upload</v-icon> Update trackingno by excel template</h3>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12"  lg="12" align="center">
                <v-file-input
                  v-model="file_excel"
                  ref="file_excel"
                  color="deep-purple accent-4"
                  counter
                  placeholder="กรุณาเลือกไฟล์ excel เท่านั้น"
                  prepend-icon="mdi-paperclip"
                  outlined
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} file_excel(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12"  lg="12" align="center" class="mb-3">
                <v-btn  color="success" @click="upload_to_update_tracking()"><v-icon left >mdi-upload</v-icon> upload excel template</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <!-- ค้นหา -->
      <v-card class="v-card-margin-bottom-30">
        <v-card-title>
          <v-icon color="blue" size="24">fa-sliders-h</v-icon>
          <span class="ml-2">ค้นหาข้อมูล</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row class="pb-2">
            <v-col class="pb-1" cols="12" md="2" lg="1"> ร้านค้า : </v-col>
            <v-col class="pb-1" cols="12" md="4">
              <v-combobox
                v-model="shop_id_by_name"
                :items="shop_id_by_name_items"
                item-key="text"
                item-value="value"
                outlined
                hide-details
              ></v-combobox>
            </v-col>
            <v-col class="pb-1" cols="12" md="2" lg="1"> ชื่อผู้รับ : </v-col>
            <v-col class="pb-1" cols="12" md="4">
              <v-text-field
                :value="SHshipName"
                @change="value => SHshipName = value"
                autocomplete="off"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col class="pb-1" cols="12" md="2" lg="1"> เลขออเดอร์ : </v-col>
            <v-col class="pb-1" cols="12" md="4">
              <v-text-field
                :value="SHorderID"
                @change="value => SHorderID = value"
                autocomplete="off"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col class="pb-1" cols="12" md="2" lg="1"/>
            <v-col cols="12" md="4" lg="5">
              <v-btn :elevation="1" class="pa-2 mt-1" color="primary" dark @click="Searching()">
                <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon> ค้นหา
              </v-btn>
              <v-btn :elevation="1" class="pa-2 ml-2 mt-1" outlined color="primary"  @click="SearchingClearData()">
                <v-icon size="14" class="pr-1">fa-solid fa-rotate</v-icon> รีเช็ต
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row class="px-0 mx-0">
        <v-col class="pa-0">
          <v-row class="mx-0 px-0">
            <v-col cols="12" class="pa-0 pl-1">
              ข้อมูลทั้งหมด {{ formatMoney(this.total_search_data_order_master) }} แถว
              ||
              แสดง :
              <select @change="set_row_per_page()" v-model="row_per_page_select_tmp" style="color: black; border: solid 1px red; padding-left: 10px; cursor: pointer;">
                 <option value="25">25</option>
                 <option value="50">50</option>
                 <option value="100">100</option>
                 <option value="200">200</option>
                 <option value="500">500</option>
                 <option value="10000000">ทั้งหมด</option>
               </select>
              แถว / หน้า
            </v-col>
            <v-col cols="12" class="px-0">
              <v-data-table
              :headers="headers1"
              :items="dataTable"
              class="elevation-1 packhai-border-table packhai-table"
              :items-per-page="row_per_page_select"
              hide-default-footer
              :page ="page">
                 <!-- <template v-slot:item.num="{ item }">
                  {{ item.num }}
                </template> -->
                <template v-slot:item.id="{ item }">
                  {{ FormatOrder(item.id) }}
                </template>
                <template v-slot:item.isCOD="{ item }">
                  <span v-if="!item.isCOD"><v-chip color="primary" dark outlined>ธรรมดา</v-chip></span>
                  <span v-else><v-chip class="CODCenter" color="error" dark>COD</v-chip></span>
                </template>
                <template v-slot:item.packedDate="{ item }">
                  {{ set_format_date_time(item.packedDate) }}
                </template>
                <!-- <template v-slot:item.trackingNo="{ item }">
                  <span > - </span>
                </template>
                <template v-slot:item.ParcelNameStatus="{ item }">
                  <v-row v-if="item.ParcelStatusID != null">
                    <v-col v-if="item.ParcelStatusID == 1" style="color: purple;">กำลังจัดส่ง</v-col>
                    <v-col v-else-if="item.ParcelStatusID == 2" style="color: red;">ตีกลับ</v-col>
                    <v-col v-else-if="item.ParcelStatusID == 3" style="color: green;">จัดส่งสำเร็จ</v-col>
                  </v-row>
                </template> -->
                <template v-slot:item.edit="{ item }">
                  <a @click="open_dialog_edit(item)">
                      <v-icon color="orange darken-3" size="18">fa-edit</v-icon>
                  </a>
                </template>
              </v-data-table>
              <div class="text-center mt-2">
                <v-pagination
                  v-model="page"
                  :length="pageLength"
                  :total-visible="pageTotalVisible"
                  color="info"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog_edit" persistent  max-width="650px">
        <v-card>
          <v-card-title >{{ dialogText }}
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog_edit = false, dialog_TrackingNo = null"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row class="mb-3">
              <v-col cols="12" sm="3" class="my-1 mb-3"><b>ชื่อผู้รับ :</b></v-col>
              <v-col cols="12" sm="9" class="my-1 mb-3">
                {{ dialog_shippName }}
              </v-col>
              <v-col cols="12" sm="3" class="my-2"><b>ขนส่ง : </b></v-col>
              <v-col cols="12" sm="9" class="my-2">
                <v-select
                  v-model="dialog_expressCompanyID"
                  :items="dialog_expressCompanyList"
                  item-text="text"
                  item-value="value"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" class="my-1"><b>TrackingNo : </b></v-col>
              <v-col cols="12" sm="9" class="my-1">
                <v-text-field
                  :value="dialog_TrackingNo"
                  @change="value => dialog_TrackingNo = value"
                  autocomplete="off"
                  ref="dialog_TrackingNo"
                  outlined
                  maxlength="20"
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="3" class="my-1"><b>ต้นทุนค่าจัดส่ง : </b></v-col>
              <v-col cols="12" sm="9" class="my-1">
                <v-text-field
                  :value="dialog_ExpressCostPrice"
                  @change="value => dialog_ExpressCostPrice = value"
                  autocomplete="off"
                  ref="dialog_ExpressCostPrice"
                  min="0"
                  outlined
                  type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"
                  hide-details
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="3" class="my-1"><b>วันที่จัดส่ง : </b></v-col>
              <v-col cols="12" sm="9" class="my-1">
              <!-- ค้นหา 2 -->
                <v-menu
                  ref="menu_search_sent_date_from"
                  v-model="menu_search_sent_date_from"
                  :close-on-content-click="false"
                  :return-value.sync="dialog_SendToExpressDatetime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computed_search_sent_date_from"
                      @change="value => computed_search_sent_date_from = value"
                      autocomplete="off"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dialog_SendToExpressDatetime" @input="menu_search_sent_date_from = false, $refs.menu_search_sent_date_from.save(dialog_SendToExpressDatetime)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mt-5" align="center">
              <v-col cols="12" align="center">
                <v-btn color="success" outlined @click="confirm_dialog_edit()" class="mx-1">บันทึก</v-btn>
                <v-btn color="info" outlined @click="clear_data_dialog()" class="mx-1">รีเซ็ต</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
  import axios from 'axios'
  import Loading from '@/website/components/Loading'
  import readXlsxFile from 'read-excel-file'
  import { shopService_dotnet, orderService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { formatMoney, FormatOrder, set_format_date_time } from '@/website/global_function'
 
  export default {
    components: {
      Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,

      navigete: [],

      search_data: [],
      dataTable: [],

      shop_id_by_name: null,
      shop_id_by_name_items: null,

      SHorderID: null,
      SHshipName: null,

      total_search_data_order_master: 0,

      dialog_edit: false,
      dialogText: null,

      dialog_OrdermasterID: null,
      dialog_shippName: null,
      dialog_expressCompanyID: null,
      dialog_expressCompanyList: [{text: 'รับสินค้าด้วยตัวเอง', value: 0}, {text: 'BEST Express', value: 1}, {text: 'Kerry Express', value: 2}, {text: 'ไปรษณีย์ EMS', value: 3}, {text: 'ไปรษณีย์ ลงทะเบียน', value: 4},
                                  {text: 'ไปรษณีย์ พัสดุธรรมดา', value: 5}, {text: 'NIM Express', value: 6}, {text: 'Flash Express', value: 7}, {text: 'SCG Express', value: 10},{text: 'J&T Express', value: 11}
                            , {text: 'Ninja Van', value: 12},{text: 'DHL Domestic', value: 13}],
      dialog_TrackingNo: null,
      dialog_ExpressCostPrice: null,


      menu_search_sent_date_from: false,
      dialog_SendToExpressDatetime: null,
      // dialog_SendToExpressDatetime: null,

      headers1: [
        // { text: 'No', align: 'center', sortable: false, value: 'num' },
        { text: 'ออเดอร์', align: 'center', value: 'id', sortable: false, width: '80px'},
        { text: 'ร้านค้า', align: 'left', value: 'shopName', sortable: false, width: '170px' },
        { text: 'ขนส่ง', align: 'left', value: 'expressCompanyName', sortable: false, width: '170px' },
        { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false , width:'120px'},
        { text: 'แพคเรียบร้อย', align: 'left', value: 'packedDate', width: '170px', sortable: false },
        { text: 'ประเภท', align: 'center', value: 'isCOD', sortable: false , width:'120px'},
        { text: 'สถานะออเดอร์', align: 'center', value: 'shippingStatusName', sortable: false, width:'150px' },
        // { text: 'สถานะพัสดุ', align: 'center', value: 'ParcelNameStatus', sortable: false, width:'150px' },
        // { text: 'TrackingNo', align: 'center', value: 'TrackingNo', width: '170px', sortable: false },
        { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width:'150px' },

      ],

      // edit for File excel
      file_excel: [],

      // row per page
      page: 1,
      page_tmp: null,
      row_per_page: 50,
      pageLength: null,
      pageTotalVisible: 9,

      row_per_page_select: 50,
      row_per_page_select_tmp: 50,
      row_per_page_list: [
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '200', value: 200},
        {text: '500', value: 500},
        {text: 'ทั้งหมด', value: 10000000},
      ],

      // excel
      excel_order_list_header : [
          { label: "OrderID", field: "id", width: 15, dataFormat: FormatOrder},
          { label: "ชื่อผู้รับ", field: "receiverName", width: 20 },
          { label: "TrackingNo", field: "", width: 20 },
          // { label: "ต้นทุนค่าขนส่ง", field: "", width: 40 },
      ],
    }),
    computed: {
      computed_search_sent_date_from() {
        return this.formatDates(this.dialog_SendToExpressDatetime)
      },
    },
    async created() {
      this.Branch_BranchID = localStorage.getItem('Branch_BranchID')
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      await this.get_shop()
      this.shop_id_by_name = this.shop_id_by_name_items[0]
      this.set_data_statPage()
      await this.load_data()
      this.page_loading = false
    },
    methods: {
      set_format_date_time,
      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      set_row_per_page () {
        this.row_per_page_select = parseInt(this.row_per_page_select_tmp)
      },

      set_data_statPage () {
        if(this.SHorderID == ''){this.SHorderID = null}
        if(this.SHshipName == ''){this.SHshipName = null}
        this.search_data = {
          'SHshopID': this.shop_id_by_name.value,
          'SHorderID': this.SHorderID,
          'SHshipName': this.SHshipName
        }
      },

      async get_shop() {
        this.loading = true
        this.shop_id_by_name_items = []

        let response = await axios.post(shopService_dotnet+'Shop/get-shop-list', {
          "branchId": this.Branch_BranchID
        }, {headers: this.header_token} )

        if(response.data.result.length > 0){
          for (var item of response.data.result) {
            var data_tmp ={
              "text": item.shop.name,
              "value": item.shop.id
            }
            this.shop_id_by_name_items.push(data_tmp)
          }
        }

        this.shop_id_by_name_items.splice(0,0,{
          "text": "ทั้งหมด",
          "value": null
        })

        this.loading = false
      },

      async load_data() {
        let response = await axios.post(orderService_dotnet+'Order/get-order-list-for-update-tracking', {
          "branchID": this.Branch_BranchID,
          "shopID": this.search_data.SHshopID,
          "shipName": this.search_data.SHshipName,
          "orderID": this.search_data.SHorderID,
        }, {headers: this.header_token} )
        this.dataTable= response.data
        this.total_search_data_order_master = response.data.length
        this.page = 1
        this.page_tmp = null
        this.row_per_page = 50
        if (response.data.length != 0) {
          if(parseInt(response.data.length) > 50){
            this.pageLength = parseInt(response.data.length/this.row_per_page)
          }else {
            this.pageLength = 1
          }
        }else {
          this.pageLength = 1
        }

        this.loading = false
      },

      SearchingClearData () {
        this.SHorderID = null
        this.SHshipName = null
        this.shop_id_by_name = this.shop_id_by_name_items[0]
        this.search_data = {
          'SHshopID': null,
          'SHorderID': null,
          'SHshipName': null
        }
      },

      async Searching () {
        this.loading = true
        this.set_data_statPage()
        this.load_data()
      },

      open_dialog_edit (item) {
        this.dialog_edit = true
        this.dialogText = FormatOrder(item['id'])
        this.dialog_OrdermasterID = item['id']
        this.dialog_shippName = item.receiverName
        this.dialog_expressCompanyID = item.expressCompanyID
        this.dialog_expressCompanyID_tmp = item.expressCompanyID
        this.dialog_ExpressCostPrice = null
        this.dialog_SendToExpressDatetime = new Date().toISOString().substr(0, 10)
      },

      clear_data_dialog () {
        this.dialog_TrackingNo = null
        this.dialog_ExpressCostPrice = null
        this.dialog_expressCompanyID = this.dialog_expressCompanyID_tmp
        this.dialog_SendToExpressDatetime = new Date().toISOString().substr(0, 10)
      },

      async confirm_dialog_edit () {
        if(this.dialog_TrackingNo == null){
          this.$refs.dialog_TrackingNo.focus()
          return
        }
        if(this.dialog_ExpressCostPrice == null || this.dialog_ExpressCostPrice == '' ){ this.dialog_ExpressCostPrice = null} else { this.dialog_ExpressCostPrice = Math.abs(this.dialog_ExpressCostPrice) }
        var data = {
          "ExpressCompanyID": this.dialog_expressCompanyID,
          "TrackingNo": this.dialog_TrackingNo,
          "ExpressCostPrice": this.dialog_ExpressCostPrice,
          "SendToExpressDatetime": this.dialog_SendToExpressDatetime
        }


        this.loading = true
        let response = await axios.post(orderService_dotnet+'Order/branch-order-update-tracking', {
          "orderMasterID": this.dialog_OrdermasterID,
          "expressCompanyID": this.dialog_expressCompanyID,
          "trackingNo": this.dialog_TrackingNo,
          "sendToExpressDatetime": this.dialog_SendToExpressDatetime
        }, {headers: this.header_token} )
          this.dialog_OrdermasterID = null
          this.dialog_TrackingNo = null
          this.dialog_edit = false
          this.clear_data_dialog()
          this.Searching()
        this.loading = false
      },


      upload_to_update_tracking () {
        if (this.file_excel == null){ this.file_excel = []}
        if (this.file_excel.length == 0) {

          this.$refs.file_excel.focus()
          return
        }
        var typeFile = false
        var file_type = this.file_excel['type']
        if(file_type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
          typeFile = true
          // } else if(this.file_excel['type'] == 'application/vnd.ms-excel'){
          //   typeFile = true
        } else {
          this.AlertWarning("ประเภทไฟล์ไม่ถูกต้อง")
          return
        }
        if(typeFile){
          // อ่านค่าบนไฟล์ excel
            readXlsxFile(this.file_excel).then(async (rows) => {
              if(rows[0][0] !='OrderID' || rows[0][2] != 'TrackingNo' ){
                this.AlertWarning("รูปแบบข้อมูลไม่ถูกต้อง")
                return
              }
              var data_trackingNo_list = []
              var i_tmp = 0
              for(var i=0; i<rows.length; i++){
                if(i>0){
                  if(rows[i][6] == '-'){
                    continue
                  } else if (rows[i][2] == '') {
                    continue
                  } else if (rows[i][2] == null) {
                    continue
                  } else {
                    var ExpressCostPrice_tmp
                    if(rows[i][3] == null || rows[i][3] == ''){
                      ExpressCostPrice_tmp = null
                    }else {
                      ExpressCostPrice_tmp = rows[i][3]
                      var checkType = Number.isInteger(parseInt(ExpressCostPrice_tmp))
                      if(!checkType){
                        continue
                      }
                    }

                    data_trackingNo_list.push(data_trackingNo_list[(i_tmp)] = {orderMasterID: rows[i][0], trackingNo: rows[i][2] })
                    i_tmp = i_tmp + 1
                  }
                }

              }
              if(data_trackingNo_list.length == 0){
                this.AlertWarning("ไม่มีข้อมูลที่ใช้ update ได้")
                return
              }
              if(data_trackingNo_list.length > 1){
                data_trackingNo_list.pop()
              }
              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/branch-excel-update-tracking', {
                "data": data_trackingNo_list
              }, {headers: this.header_token} )
              this.AlertSuccess()
              this.file_excel = null
              this.Searching()
              this.loading = false
            })

        }
      },
      formatMoney,
      FormatOrder,
      AlertSuccess,
      AlertWarning,
      AlertError,
    }
  }
</script>

<style scoped>
  >>>.bex48-logo {
    width: 12.533vw;
    height: 11.2vw;
    display: block;
    margin: 1.6vw auto 1.6vw;
  }
  >>>.slide-fade-enter-active {
    transition: all .3s ease;
  }
  >>>.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  >>>.slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
  .packhai-border-table{
    border:1px solid rgba(0, 0, 0, 0.12);
    border-radius:4px;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    font-weight: bold;
    font-size: 1rem;
  }
  .return-order-table{
    width: 100%;
  }
  .padding-for-order-dialog{
    padding: 0px !important ;
  }
  @media screen and (min-width: 768px) {
    .return-order-table {
      width: 300px;
    }
    .padding-for-order-dialog{
      padding: 15px !important;
    }
  }
  .px-15{
    padding-left: 15px;
    padding-right:  15px;
  }
</style>
